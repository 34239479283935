import {useRef} from 'react'

/**
 * React hook to check for an equal value, to preserve Object.is checks.
 */
export const useEqualBy = <T>(fn: (a: T, b: T) => boolean, x: T): T => {
  const xRef = useRef<T | undefined>()
  if (xRef.current === undefined || !fn(x, xRef.current)) {
    xRef.current = x
  }
  return xRef.current as T
}
