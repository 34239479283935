import {logger} from 'tizra'

const log = logger('field-types/year')

export const year = {
  defaultValue: {operator: ''},
  test: function (v) {
    return v.operator !== ''
  },
  widget: 'year-range',
  mobileFilters: {
    abbrev: function (v) {
      // prettier-ignore
      switch (v.operator) {
        case '=': return v.exact
        case '>': return `from ${v.low}`
        case '<': return `until ${v.high}`
        case '-': return v.low + '-' + v.high
        default: log.warn('years.abbrev: operator is ' + v.operator)
      }
    },
  },
  filterTags: {
    label: function (v) {
      // prettier-ignore
      switch (v.operator) {
        case '=': return 'year'
        case '>': return 'from'
        case '<': return 'until'
        case '-': return 'years'
        default: log.warn('years.label: operator is ' + v.operator)
      }
    },
    abbrev: function (v) {
      // prettier-ignore
      switch (v.operator) {
        case '=': return v.exact
        case '>': return v.low
        case '<': return v.high
        case '-': return v.low + '-' + v.high
        default: log.warn('years.abbrev: operator is ' + v.operator)
      }
    },
  },
  api: {
    contribute: ({field, value, utils: {all, propParam}}) =>
      all(propParam(field, value)),
  },
}
